import React from "react"
import "./workzone.scss"

// import Swiper core and required modules
import { Autoplay,Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import { titleDesign } from "../../../utils"

import SliderNavigation from "../SharedComponents/SliderNavigation"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import { createMarkup, string_translation } from "../../../utils"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function WorkzoneSlider(props) {
  const swiperRef = React.useRef(null)
  const { data } = props

  const {
    locale,
    originalUrl, // ar
  } = useContext(TranslateUrlsContext)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        learnmore_ar:translateString(language: AR, string: "اعرف اكثر")
        learnmore_en:translateString(language: EN, string: "Learn More")
      }
    }
  `)
  const shouldRenderArrows = props?.data?.allWpWorkzone?.edges?.length > 1
  const wzlist = props?.data?.allWpWorkzone?.edges
  let newtodos = []

  wzlist?.map((item, index) => {
    if (item.node.language.locale.indexOf(locale) !== -1) {
      return newtodos.push(item)
    }
  })
  return (
    <>
      <section className="newsroom-first-block-work-zone">
        <div className="container">
          
        <Swiper
                // install Swiper modules
                 // install Swiper modules
                 modules={[Autoplay,Navigation, Pagination]}
                 pagination={{ clickable: true }}
                 autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                 }}
                 id="work-zone-updates"
                 spaceBetween={5}
                 slidesPerView={1}
                 slidesPerGroup={1}
                 grabCursor={"true"}
                 ref={swiperRef}
                 dir={locale === "en" ? "ltr" : "rtl"}
              >
                {newtodos?.map((workzone,index) => (
                  <SwiperSlide >
                    <div className="col-group">
                  <div className="col-dt-7">
                    <div className="img-wrapper">
                      <img  
                        src={workzone?.node?.featuredImage?.node?.localFile
                          ?.publicURL}
                        alt={workzone?.node?.featuredImage?.node?.altText || workzone?.node?.title}
                      />
                    </div>
                  </div>
                  <div className="col-dt-5">
                    <div className="second-col-wrapper">
                      <div className="lifestyle-wrapper">
                        <div
                          className="lifestyle-title h2"
                          dangerouslySetInnerHTML={titleDesign(workzone?.node?.title)}
                        ></div>
                        <div
                          className="lifestyle-desc"
                          dangerouslySetInnerHTML={createMarkup(
                            (workzone?.node?.content)
                          )}
                        ></div>
                        <div className="read-the-story">
                          <div className="learn-more">
                         <Link
                    to={workzone?.node?.uri}
                    state={{ parenturl: originalUrl }}
                    className="download-btn"
                  >
                              {string_translation(
                                stringTranslations,
                                "learnmore",
                                locale
                              )}
                              </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </SwiperSlide> 
                ))}

{shouldRenderArrows && <SliderNavigation ref={swiperRef} />}


              </Swiper>
          
                
               
                
            
            <div className="col-group">
              <div className="col-dt-7"></div>
              <div className="col-dt-5">
                   &nbsp;
              </div>
            </div>
         
        </div>
      </section>
    </>
  )
}
