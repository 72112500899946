import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import Workzone from '../components/WorkZone'
import LatestWorkZones from '../components/LatestWorkZones'
import WhoWeAreWorkZone from '../components/WhoWeAreWorkZone'
import GetintouchProject from '../components/GetinTouchProject'
import WorkzoneSlider from '../components/WorkZoneSlider'
import WhoWeAreVendor from '../components/WhoWeAreVendor'
import WhoWeAre from '../components/WhoWeAre'
export default function WorkZones(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri
    return (
        <>
        <Seo data={seo}/>
        <Layout translatedURI={translatedURI}>
        <div className="work-zone-who-we-are">
        <WhoWeAre
            title={data.wpPage.pageSubtitle?.subtitle}
            name={data.wpPage?.title}
            description={data.wpPage.pageDescription?.pageDescription}
          />
        </div>
        {(data?.allWpWorkzone?.edges.length>1)?
           <WorkzoneSlider data={data}/>:''

        }
        
          
          {(data?.allWpWorkzone?.edges.length>1)?
          <LatestWorkZones data={data}/>:''
          }
    <GetintouchProject data={data?.wpPage?.workZoneUpdates?.contactForm} locale={locale}/>
        </Layout>
        </>
    )
}

export const pageQuery = (graphql` query workZoneData($databaseId:Int) {


    allWpWorkzone(sort: {fields: date, order: DESC}) {
      edges {
        node {
          language {
            id
            locale
          }
          id
          title
          
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
         content
          slug
          uri
          translations {
            uri
          }
        }
      }
    }
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        altText
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    workZoneUpdates {
      contactForm
      mainWorkZone {
        ... on WpWorkzone {
          title
          
          excerpt
          date(formatString: "DD-MM-YYYY")
          featuredImage {
            node {
              altText
              localFile {
                publicURL
              }
            }
          }
         content
          slug
          uri
          translations {
            uri
          }
        }
      }
    }
  }
}`);