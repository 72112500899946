import React, { useState, useContext } from "react"
import "./getInProject.scss"
import {useStaticQuery, graphql, navigate } from "gatsby"
import Cf7FormWrapper from "../cf7-form-wrapper"
import { string_translation } from "../../../utils"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"
import countries from "../../../static/countries"

function Form({ handler, isLoading, isSent, hasError, validationErrors, stringTranslations }) {
  const [formState, setFormState] = useState({})
  let roles=[
    {
      "en":"Owner",
      "ar":"مالك"
    },
    {
      "en":"Engineering Office / Architect",
      "ar":"مكتب هندسي / معماري"
    },
    {
      "en":"Potential Buyer",
      "ar":"مشتري محتمل"
    },
    {
      "en":"Agency / Agent",
      "ar":"وكالة / وكيل"
    },
    {
      "en":"Other",
      "ar":"أخرى"
    }


  ];

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFileChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.files[0],
    })
  }

 

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <p>{errors.errors}</p>
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  if(isSent) {
    navigate("/"+locale+'/success')
  }

  return (
    <form
      onSubmit={handleFormSubmit}
    >
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}

      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Form Logo"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Form Logo Mobile"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"lname", locale)}
                  // value={lastname}
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"email", locale)}
                  // value={email}
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"phone", locale)}
                  // value={number}
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div> 
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"role", locale)}
                  // value={mediaOutlet}
                  onChange={(e) => handleFieldChange("role", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
             
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations,"complainlocation", locale)}
                  // value={mediaOutlet}
                  onChange={(e) => handleFieldChange("complainlocation", e)}
                />
               
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
              <input
                type="text"
                placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                onChange={(e) => handleFieldChange("msg", e)}
              />
              </div>
              <div className="col-dt-6 label-2" >
              <div className="select-wrapperr">
                <input
                  type="file"
                  title={string_translation(stringTranslations,"photoupload", locale)}
                  placeholder={string_translation(stringTranslations,"photoupload", locale)}
                  // value={mediaOutlet}
                  accept="image/*"
                  id="file-input"
                  onChange={(e) => handleFileChange("photoupload", e)}
                />

    <label id="file-input-label" for="file-input"
      >{string_translation(stringTranslations,"photoupload", locale)}</label>
             </div>
              </div>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
            <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
          
          </div>

          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )

}

export default function GetintouchProject({data, locale}) {


  const { stringTranslations, site } = useStaticQuery(graphql`
    query {
      stringTranslations {
        project_form_smalltitle_ar:translateString(language: AR, string: "تواصل معنا في حال كنت  أحد الملاك حول مشروع المسار الرياضي ولديك شكوى أو استفسار")
        project_form_smalltitle_en:translateString(language: EN, string: "Contact us If you are a property owner near to the project and you have an inquiry or complaint")
        project_form_bigtitle_ar:translateString(language: AR, string: "NEWSROOM FORM TITLE 2")
        project_form_bigtitle_en:translateString(language: EN, string: "NEWSROOM FORM TITLE 2")

        fname_ar:translateString(language: AR, string: "FIRST NAME")
        fname_en:translateString(language: EN, string: "FIRST NAME")
        lname_ar:translateString(language: AR, string: "LAST NAME")
        lname_en:translateString(language: EN, string: "LAST NAME")
        email_ar:translateString(language: AR, string: "EMAIL")
        email_en:translateString(language: EN, string: "EMAIL")
        phone_ar:translateString(language: AR, string: "PHONE")
        phone_en:translateString(language: EN, string: "PHONE")
        media_outlet_ar:translateString(language: AR, string: "MEDIA OUTLET")
        media_outlet_en:translateString(language: EN, string: "MEDIA OUTLET")
        country_ar:translateString(language: AR, string: "COUNTRY")
        country_en:translateString(language: EN, string: "COUNTRY")
        role_ar:translateString(language: AR, string: "الصفة")
        role_en:translateString(language: EN, string: "Role")
        msg_ar:translateString(language: AR, string: "Enquiry/Message")
        msg_en:translateString(language: EN, string: "Enquiry/Message")
        submit_ar:translateString(language: AR, string: "SUBMIT")
        submit_en:translateString(language: EN, string: "SUBMIT")
        try_again_ar:translateString(language: AR, string: "TRY AGAIN")
        try_again_en:translateString(language: EN, string: "TRY AGAIN")
        submission_error_ar:translateString(language: AR, string: "SUBMISSION ERROR")
        submission_error_en:translateString(language: EN, string: "SUBMISSION ERROR")
        loading_ar:translateString(language: AR, string: "LOADING")
        loading_en:translateString(language: EN, string: "LOADING")
        complainlocation_ar:translateString(language: AR, string: "رابط موقع الشكوى")
        complainlocation_en:translateString(language: EN, string: "Complain Location Link")
        photoupload_ar:translateString(language: AR, string: "إرفاق صورة (إن وجد)")
        photoupload_en:translateString(language: EN, string: "Attach a photo (if available)")
        ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
        ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
        ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
        ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
        
      }
      site {
        siteMetadata {
          formSubmissionUrl
        }
      }
    }
  `)

  return (
    <>
      <section className="get-in-touch">
        <div className="container">
          <div className="form-wrapper">
            <div className="small-title h2">{string_translation(stringTranslations,"project_form_smalltitle", locale)}</div>
            <div className="big-title h2">{string_translation(stringTranslations," project_form_bigtitle", locale)}</div>
            <div className="get-in-touch-form-wrapper">
              <Cf7FormWrapper siteUrl={site.siteMetadata.formSubmissionUrl} formId={data}>
                <Form stringTranslations={stringTranslations}/>
              </Cf7FormWrapper>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
